import { secondsToMMSS } from '@/shared/time-helper';

const timerIntervals = {};
const RADIUS = 20;
const CIRCUMFERENCE = 2 * Math.PI * RADIUS;

function saveTimer(timer) {
  const form = $(timer).children('.timer-form');
  const time = timer.getElementsByClassName('time-counter')[0].getAttribute('time');
  form.children('#timer_time').val(time);
  form.trigger('submit.rails');
}

function pause(id) {
  window.clearInterval(timerIntervals[id]);
  delete timerIntervals[id];

  const timer = document.getElementById(id);
  const button = timer.getElementsByClassName('play-pause-btn')[0];
  button.className = 'fa fa-play play-btn play-pause-btn';

  saveTimer(timer);
}

function progress(timer, value) {
  const localProgress = value / 100;
  const dashoffset = CIRCUMFERENCE * (1 - localProgress);

  const progressValue = timer.getElementsByClassName('circle-timer-value')[0];

  if (value < 10) {
    progressValue.style.stroke = 'rgb(255, 0, 0)';
  } else if (value < 20) {
    progressValue.style.stroke = 'rgb(255, 220, 0)';
  }

  progressValue.style.strokeDashoffset = dashoffset;
}

function drawTimer(timer) {
  const counter = timer.getElementsByClassName('time-counter')[0];
  const value = parseInt(counter.getAttribute('time'), 10);
  counter.textContent = secondsToMMSS(value);
  const timeLimit = parseInt(timer.getAttribute('time_limit'), 10);
  progress(timer, (value / timeLimit) * 100);
}

function decreaseCounter(timer) {
  const counter = timer.getElementsByClassName('time-counter')[0];
  const value = parseInt(counter.getAttribute('time'), 10);
  if (value === 0) {
    window.clearInterval(timerIntervals[timer.id]);
    saveTimer(timer);
    return;
  }
  counter.setAttribute('time', value - 1);
  drawTimer(timer);
}

function play(id) {
  for (const key in timerIntervals) {
    if (Object.hasOwn(timerIntervals, key)) {
      pause(key);
    }
  }

  const timer = document.getElementById(id);
  timerIntervals[id] = window.setInterval(decreaseCounter, 1000, timer);

  const button = timer.getElementsByClassName('play-pause-btn')[0];
  button.className = 'fa fa-pause pause-btn play-pause-btn';
}

function handleClick() {
  const timer = this.parentElement;
  if (Object.hasOwn(timerIntervals, timer.id)) {
    pause(timer.id);
  } else {
    play(timer.id);
  }
}

window.onload = function onLoad() {
  const buttons = document.getElementsByClassName('play-pause-btn');
  for (const b of buttons) {
    b.onclick = handleClick;
  }

  const circles = document.getElementsByClassName('circle-timer-value');
  for (const c of circles) {
    c.style.strokeDasharray = CIRCUMFERENCE;
  }

  const timers = document.getElementsByClassName('timer');
  for (const t of timers) {
    drawTimer(t);
  }
};
