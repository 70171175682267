$(() => {
  const $addSecondaryInterviewer = $('#interview_add_secondary_interviewer');
  const $huntflowIntervieweeId = $('#huntflow_interviewee_id');
  const $vacancy = $('#vacancy');

  $addSecondaryInterviewer.on('change', () => {
    const checked = $addSecondaryInterviewer.is(':checked');

    $('.interview_secondary_interviewer').toggleClass('d-none', !checked);
    $('#interview_secondary_interviewer_id').prop('disabled', checked ? false : 'disabled');
  });

  $vacancy.on('change', () => {
    $huntflowIntervieweeId.text('');
    $huntflowIntervieweeId.data('select2').results.clear();
    $('#interview_interviewee_name').val('');
  });

  $huntflowIntervieweeId.on('change', () => {
    const intervieweeName = $('#huntflow_interviewee_id option:selected').text();
    $('#interview_interviewee_name').val(intervieweeName);
  });

  $vacancy.select2({
    minimumResultsForSearch: -1,
    dropdownCssClass: 'no-search',
    ajax: {
      delay: 250,
    },
  });

  $huntflowIntervieweeId.select2({
    minimumResultsForSearch: -1,
    dropdownCssClass: 'no-search',
    ajax: {
      delay: 250,
      data: () => {
        const vacancyId = $('#vacancy').val();
        const query = { vacancy_id: vacancyId };
        return query;
      },
    },
  });
});
