(function init($) {
  let cocoonElementCounter = 0;

  function createNewId() {
    cocoonElementCounter += 1;
    return new Date().getTime() + cocoonElementCounter;
  }

  function newcontentBraced(id) {
    return `[${id}]$1`;
  }

  function newcontentUnderscord(id) {
    return `_${id}_$1`;
  }

  function getInsertionNodeElem(insertionNode, insertionTraversal, $this) {
    if (!insertionNode) {
      return $this.parent();
    }

    if (typeof insertionNode === 'function') {
      if (insertionTraversal) {
        console.warn(
          'association-insertion-traversal is ignored, because association-insertion-node is given as a function.',
        );
      }
      return insertionNode($this);
    }

    if (typeof insertionNode === 'string') {
      if (insertionTraversal) {
        return $this[insertionTraversal](insertionNode);
      }
      return insertionNode === 'this' ? $this : $(insertionNode);
    }

    return null;
  }

  $(document).on('click', '.add_fields', function onClick(e) {
    e.preventDefault();
    const $this = $(this);
    const assoc = $this.data('association');
    const assocs = $this.data('associations');
    const content = $this.data('association-insertion-template');
    const insertionMethod =
      $this.data('association-insertion-method') || $this.data('association-insertion-position') || 'before';
    const insertionNode = $this.data('association-insertion-node');
    const insertionTraversal = $this.data('association-insertion-traversal');
    let count = parseInt($this.data('count'), 10);
    let regexpBraced = new RegExp(`\\[new_${assoc}\\](.*?\\s)`, 'g');
    let regexpUnderscord = new RegExp(`_new_${assoc}_(\\w*)`, 'g');
    let newId = createNewId();
    let newContent = content.replace(regexpBraced, newcontentBraced(newId));
    let newContents = [];

    if (newContent === content) {
      regexpBraced = new RegExp(`\\[new_${assocs}\\](.*?\\s)`, 'g');
      regexpUnderscord = new RegExp(`_new_${assocs}_(\\w*)`, 'g');
      newContent = content.replace(regexpBraced, newcontentBraced(newId));
    }

    newContent = newContent.replace(regexpUnderscord, newcontentUnderscord(newId));
    newContents = [newContent];

    count = Number.isNaN(count) ? 1 : Math.max(count, 1);
    count -= 1;

    while (count) {
      newId = createNewId();
      newContent = content.replace(regexpBraced, newcontentBraced(newId));
      newContent = newContent.replace(regexpUnderscord, newcontentUnderscord(newId));
      newContents.push(newContent);

      count -= 1;
    }

    const insertionNodeElem = getInsertionNodeElem(insertionNode, insertionTraversal, $this);

    if (!insertionNodeElem || insertionNodeElem.length === 0) {
      console.warn(
        "Couldn't find the element to insert the template. Make sure your `data-association-insertion-*` on `link_to_add_association` is correct.",
      );
    }

    $.each(newContents, (i, node) => {
      const contentNode = $(node);

      const beforeInsert = jQuery.Event('cocoon:before-insert');
      insertionNodeElem.trigger(beforeInsert, [contentNode]);

      if (!beforeInsert.isDefaultPrevented()) {
        // allow any of the jquery dom manipulation methods (after, before, append, prepend, etc)
        // to be called on the node.  allows the insertion node to be the parent of the inserted
        // code and doesn't force it to be a sibling like after/before does. default: 'before'
        insertionNodeElem[insertionMethod](contentNode);

        insertionNodeElem.trigger('cocoon:after-insert', [contentNode]);
      }
    });
  });

  $(document).on('click', '.remove_fields.dynamic, .remove_fields.existing', function onClick(e) {
    const $this = $(this);
    const wrapperClass = $this.data('wrapper-class') || 'nested-fields';
    const nodeToDelete = $this.closest(`.${wrapperClass}`);
    const triggerNode = nodeToDelete.parent();

    e.preventDefault();

    const beforeRemove = jQuery.Event('cocoon:before-remove');
    triggerNode.trigger(beforeRemove, [nodeToDelete]);

    if (!beforeRemove.isDefaultPrevented()) {
      const timeout = triggerNode.data('remove-timeout') || 0;

      setTimeout(() => {
        if ($this.hasClass('dynamic')) {
          nodeToDelete.detach();
        } else {
          $this.prev('input[type=hidden]').val('1');
          nodeToDelete.hide();
        }
        triggerNode.trigger('cocoon:after-remove', [nodeToDelete]);
      }, timeout);
    }
  });

  $(document).on('ready page:load turbolinks:load', () => {
    $('.remove_fields.existing.destroyed').each(function onEach() {
      const $this = $(this);
      const wrapperClass = $this.data('wrapper-class') || 'nested-fields';

      $this.closest(`.${wrapperClass}`).hide();
    });
  });
})(jQuery);
