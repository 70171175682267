$(() => {
  /**
   * функция получения get-параметров
   */
  function getParameterByName(name, url) {
    if (!url) {
      // eslint-disable-next-line no-param-reassign
      url = window.location.href;
    }

    // eslint-disable-next-line no-param-reassign
    name = name.replace(/[[\]]/g, '\\$&');

    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);

    if (!results) {
      return null;
    }

    if (!results[2]) {
      return '';
    }

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  const scopeId = getParameterByName('scope_id');
  const challengeModuleId = getParameterByName('challenge_module_id');

  $('.js-scopes').on('click', '.js-scope-switcher:not(.btn-shadow-info)', (event) => {
    const jTarget = $(event.currentTarget);
    $('.js-scope-switcher').removeClass('btn-shadow-info active').addClass('btn-light non-active');
    jTarget.removeClass('btn-light non-active').addClass('btn-shadow-info active');
    const scope = jTarget.data('target');
    const jContainer = $(`.js-scope-content[data-scope=${scope}]`);
    $('.js-scope-content:visible').fadeOut(300, () => {
      jContainer.fadeIn(300);
    });
  });

  // открываем нужные вкладки если есть scope в url
  if (scopeId) {
    $(`.js-scope-switcher[data-target=${scopeId}]`).click();

    // открываем грейд если он есть
    if (challengeModuleId) {
      setTimeout(() => {
        $(`.b-challenge-modules .card[data-target-challenge_module-id=${challengeModuleId}] .js-show-popup`).click();
      });
    }
  }
});
