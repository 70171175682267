import 'jquery';
import 'jquery-ujs';
import 'bootstrap';
import 'jquery-bar-rating';
import 'jquery-form-validation';

import './select2';
import './cocoon';
import './print.min';

$(() => {
  $('a[disabled=disabled]').click((event) => {
    event.preventDefault();
    event.stopPropagation();
  });
});
