$(() => {
  const $editVerificationItems = $('.js-edit-verification-item');

  if ($editVerificationItems.length === 0) return;

  function submitData(submitUrl, data, callback) {
    $.ajax({
      url: submitUrl,
      type: 'POST',
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      data,
      success(resData) {
        if (typeof callback === 'function') {
          callback(resData);
        }
      },
    });
  }

  function scrollLog() {
    $('.event-logs-entries').scrollTop($('.event-logs-entries')[0].scrollHeight);
  }

  function pasteLogEntries(entries) {
    const entriesHTML = $.map(
      entries,
      (entry) =>
        `<div class="alert ${entry.label_class}"><p class="log-entry-content">${entry.content}</p><p class="small log-entry-time">${entry.date_time}</p></div>`,
    );

    $('.event-logs-placeholder').remove();
    $('.event-logs-entries').append(entriesHTML);
    scrollLog();
  }

  $editVerificationItems.each((idx, item) => {
    const $item = $(item);
    const $editVerificationComment = $item.find('.js-edit-verification-comment');
    const $editVerificationSelect = $item.find('.js-edit-verification-rating');
    const submitUrl = $item.data('verification-update-path');
    const conductorType = $item.data('conductor-type');
    const conductorValue = `verification[${conductorType}_value]`;
    const conductorComment = `verification[${conductorType}_comment]`;

    const data = {
      _method: 'patch',
      conductorValue: null,
      conductorComment: '',
      'verification[id]': null,
    };

    if ($editVerificationSelect.val()) {
      data[conductorValue] = $editVerificationSelect.val();
    } else {
      data[conductorValue] = 0;
    }

    if ($editVerificationComment.val()) {
      data[conductorComment] = $editVerificationComment.val();
    } else {
      data[conductorComment] = '';
    }

    data['verification[id]'] = $item.data('verification-id');

    $editVerificationSelect.barrating({
      theme: 'bars-square',
      showValues: true,
      showSelectedRating: false,
      allowEmpty: true,
      emptyValue: '',
      deselectable: true,
      onSelect(value) {
        data[conductorValue] = value;
        submitData(submitUrl, data, pasteLogEntries);
      },
    });

    $editVerificationComment.on('change', () => {
      data[conductorComment] = $editVerificationComment.val();
      submitData(submitUrl, data, pasteLogEntries);
    });
  });

  function copyPracticalAssignmentContent(button) {
    button.parents('.modal-content').find('#practical_assignment_content')[0].select();
    document.execCommand('copy');
    button.tooltip('show');
    setTimeout(() => {
      button.tooltip('hide');
    }, 1000);
  }

  function pasteLogComment(comment) {
    pasteLogEntries([comment]);
    $('#log_entry_text').val('');
  }

  function resetHowToCheck({ hide = 'empty', show = 'initial' } = {}) {
    $('.how-to-check-content').html('');
    $(`.how-to-check-placeholder-${hide}`).hide();
    $(`.how-to-check-placeholder-${show}`).show();
  }

  function shoHowToCheck(requirementID) {
    const howToCheckContent = $(`.how-to-check-source[data-requirement-id="${requirementID}"]`).html();
    if (howToCheckContent) {
      $('.how-to-check-placeholder').hide();
      $('.how-to-check-content').html(howToCheckContent);
    } else {
      resetHowToCheck({ hide: 'initial', show: 'empty' });
    }
  }

  $('.verifications-block').on('show.bs.collapse', () => {
    resetHowToCheck();
  });

  $('.js-edit-verification-comment').click((e) => {
    e.stopPropagation();
  });

  $(document).on('click', '.copy-practical-assignment-content', function onClick() {
    copyPracticalAssignmentContent($(this));
  });

  $('.event-log-form').on('ajax:success', (_event, data) => {
    pasteLogComment(data);
  });

  $(document).ready(() => {
    $('[data-toggle="popover"]').popover();
    scrollLog();
  });

  $(document).on('click', 'tr.js-edit-verification-item', function onClick() {
    shoHowToCheck($(this).attr('requirement_id'));
  });
});
